import React, { useContext, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Methods from "../../../utils/https";
import { notify } from "../../../utils/notify";
import { UserContext } from "../../../context/UserContext";
import {
  createTextDispersionFile,
  saveDispersionFileAndRecords,
  canDisperseByValidations,
  disperseInterns,
} from "../helpers";
import { DownloadDispersionFile } from "./DownloadDispersionFile";
import moment from "moment";
import { ConfirmDisperse } from "./ConfirmDisperse";
import { DeleteFileConfirmation } from "./DeleteFileConfirmation";

export default function Disperse({
  selectedInterns,
  showDisperse,
  setShowDisperse,
  payDate,
  nextNumber,
  selectedBank,
  dateToDisperse,
  updateList,
  setUpdateList,
  someoneIsAlreadyDispersed,
  setSelected,
  dispersionTypeSelected,
  currentPayDay,
}) {
  const [startDate, endDate] = payDate;
  const [txtLink, setTxtLink] = useState("");
  const context = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [dispersed, setDispersed] = useState(false);
  const [fileExistAlready, setFileExistAlready] = useState({
    exist: false,
    fileId: "",
  });
  const [pendingDispersion, setIsPendingDispersion] = useState({
    exist: false,
    fileId: "",
  });
  const [isValidatingFiles, setIsValidatingFiles] = useState(false);

  useEffect(() => {}, [selectedInterns]);

  useEffect(() => {
    dispersionFilesValidations();
  }, [showDisperse]);

  const dispersionFilesValidations = async () => {
    if (showDisperse == true) {
      setIsValidatingFiles(true);
      await Promise.all([
        verifyPendingDispersions(),
        verifyDuplicatedDispersionNumber(),
      ]);
      console.log(selectedInterns);
      setIsValidatingFiles(false);
    }
  };

  const verifyDuplicatedDispersionNumber = async () => {
    const getAllItems = false;
    let records = await Methods.getDispersionRecorsdByPayDay(
      currentPayDay,
      dispersionTypeSelected,
      nextNumber,
      getAllItems
    );

    if (records?.length) {
      setFileExistAlready({
        exist: true,
        fileId: records[0].fileId,
      });
    }

    let result = [];
    selectedInterns.forEach((item) => {
      if (item?.isSelected && item?.disabledCompany) {
        result.push(item);
      }
    });
    selectedInterns = result;
  };

  const verifyPendingDispersions = async () => {
    let validatePendingDispersion =
      await Methods.getDispersionPendingRecorsdByEmail(context.user.data.email);
    if (validatePendingDispersion?.length) {
      setIsPendingDispersion({
        exist: true,
        fileId: validatePendingDispersion[0].fileId,
      });
    }
  };

  const disperse = async (e) => {
    e.preventDefault();
    const confirmation = e.target.elements.confirmation.value;
    let dispersionFile = setFileName(selectedBank, dateToDisperse);

    if (
      !canDisperseByValidations(
        confirmation,
        selectedInterns,
        context,
        dispersionTypeSelected,
        nextNumber,
        selectedBank
      )
    )
      return;

    setIsLoading(true);

    try {
      await disperseInterns(selectedInterns, payDate);
      const textFile = createTextDispersionFile(
        selectedInterns,
        selectedBank,
        dateToDisperse,
        nextNumber
      );
      await saveDispersionFileAndRecords(
        textFile,
        selectedInterns,
        context.user.data,
        payDate[0],
        nextNumber,
        dispersionTypeSelected,
        selectedBank,
        pendingDispersion?.fileId,
        fileExistAlready?.fileId,
        dispersionFile,
        dateToDisperse
      );
      if (txtLink !== "") window.URL.revokeObjectURL(txtLink);
      setTxtLink(window.URL.createObjectURL(textFile));
      setDispersed(true);
    } catch (e) {
      notify("Algo salío mal, intentalo de nuevo", "error");
    }

    setFileExistAlready({ exist: false, fileId: "" });
    setIsPendingDispersion({ exist: false, fileId: "" });
    setIsLoading(false);
  };

  const handleClose = () => {
    setUpdateList(!updateList);
    setShowDisperse(!showDisperse);
    setTimeout(() => setDispersed(false), 1000);
    selectedInterns.forEach((internAux) => {
      internAux.dispersionStatus = "Dispersado";
    });
    setSelected([]);
  };

  const setFileName = (selectedBank, startDate) => {
    let response = "";
    if (selectedBank !== "Bancomer" && selectedBank !== "Santander") {
      const consecutive = ("00" + nextNumber).slice(-2);
      response = `NI34954${consecutive}`;
    } else {
      response =
        selectedBank.toUpperCase() + "_" + moment(startDate).format("YYYYMMDD");
    }
    return response;
  };

  const handleCancel = () => {
    setShowDisperse(!showDisperse);
    setFileExistAlready({
      exist: false,
      fileId: "",
    });
    setIsPendingDispersion({
      exist: false,
      fileId: "",
    });
  };

  const showConfirmation = () =>
    !dispersed && !fileExistAlready.exist && !pendingDispersion.exist;

  return (
    <Modal
      className={
        dispersed ? "success-modal dispersion-modal" : "prefacture-modals"
      }
      onHide={() => {}}
      show={showDisperse}
      centered
    >
      {isValidatingFiles ? (
        <>
          <h4 className="centered-component">Validando información</h4>
          <Spinner
            animation="border"
            role="status"
            className="centered-component"
          />
        </>
      ) : (
        <>
          {pendingDispersion.exist && (
            <DeleteFileConfirmation
              label={
                "Tienes una dispersión pendiente por terminar, si continuas se eliminarán los registros"
              }
              onConfirmAction={setIsPendingDispersion}
              onCancelAction={handleCancel}
              fileValues={pendingDispersion}
            />
          )}

          {fileExistAlready.exist && !pendingDispersion.exist && (
            <DeleteFileConfirmation
              label={
                "Ya existe un registro de dispersión con el mismo número, si continuas se borrará el registro"
              }
              onConfirmAction={setFileExistAlready}
              onCancelAction={handleCancel}
              fileValues={fileExistAlready}
            />
          )}

          {showConfirmation() && (
            <ConfirmDisperse
              disperse={disperse}
              someoneIsAlreadyDispersed={someoneIsAlreadyDispersed}
              selectedInterns={selectedInterns}
              isLoading={isLoading}
              setShowDisperse={setShowDisperse}
              showDisperse={showDisperse}
            />
          )}

          {dispersed && (
            <DownloadDispersionFile
              setFileName={setFileName}
              selectedBank={selectedBank}
              startDate={startDate}
              handleClose={handleClose}
              txtLink={txtLink}
            />
          )}
        </>
      )}
    </Modal>
  );
}
