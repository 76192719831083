import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Table,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCloudDownloadAlt,
  faExclamationTriangle,
  faCloudUploadAlt,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import Methods from "../../../utils/https";
import { notify } from "../../../utils/notify";
import { UserContext } from "../../../context/UserContext";
import { BiArrowBack } from "react-icons/bi";
import { FactureContacts } from "./FactureContacts";
import { decrypt } from "../../../utils/crypto";
const uuid = require("uuid");
const axios = require("axios");

export default function VerifyCompanyData({
  companies,
  currentCompanyId,
  setTab,
  updateList,
  setUpdateList,
  pendingCompanies,
}) {
  const context = useContext(UserContext);
  const canEdit = context.can("update", "Companies");
  const [currentCompany, setCurrentCompany] = useState({});
  const [currentCompanyAux, setCurrentCompanyAux] = useState({});
  const [companyDocs, setCompanyDocs] = useState([]);
  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [showAgent, setShowAgent] = useState(false);
  const [errorAgent, setErrorAgent] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhoneSec, setErrorPhoneSec] = useState(false);
  const [errorEmailSec, setErrorEmailSec] = useState(false);
  const [errorPhoneThr, setErrorPhoneThr] = useState(false);
  const [errorEmailThr, setErrorEmailThr] = useState(false);
  const [labelPhone, setLabelPhone] = useState("");
  const [labelAgent, setLabelAgent] = useState("");
  const [labelEmail, setLabelEmail] = useState("");
  const [labelPhoneSec, setLabelPhoneSec] = useState("");
  const [labelEmailSec, setLabelEmailSec] = useState("");
  const [labelPhoneThr, setLabelPhoneThr] = useState("");
  const [labelEmailThr, setLabelEmailThr] = useState("");
  const [errorRfc, setErrorRfc] = useState(false);
  const [labelErrorRFC, setLabelRFC] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [currentSuburbs, setCurrentSuburbs] = useState([]);
  const [currentMunicipality, setCurrentMunicipality] = useState("");
  const [currentContacts, setCurrentContacts] = useState([
    { _id: uuid.v1(), name: "", email: "" },
  ]);
  const docNames = [
    "Comprobante de Domicilio",
    "Poder Legal",
    "Acta Constitutiva",
    "Estado de Cuenta",
    "INE Contacto Recursos Humanos",
    "Constancia RFC",
    "INE Representante Legal",
  ];
  const regexEmail = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/
  );
  const isCaintraUser = context.user.data.isCaintraUser;
  const [factureAditionalOptions, setFactureAditionalOptions] = useState([]);

  const [factureAux, setFactureAux] = useState({
    currentFacturePaymentType: undefined,
    currentCFDI: undefined,
    currentFacturePaymentMethod: undefined,
    currentFactureAgent: undefined,
    defaultValueFacturePayment: undefined,
  });

  const {
    currentFacturePaymentType,
    currentCFDI,
    currentFacturePaymentMethod,
    currentFactureAgent,
  } = factureAux;

  useEffect(() => {
    getCurrentCompany();
  }, [updateInfo]);

  const setFacturesValues = (valueId, prefactureOptionsAux) => {
    return valueId
      ? prefactureOptionsAux.find((option) => option._id == valueId)
      : undefined;
  };

  const setDefaulFactureOptionValue = (type, prefactureOptionsAux) => {
    for (let option of prefactureOptionsAux) {
      if (option.type == type) {
        return option;
      }
    }
  };

  const verifyRfc = async () => {
    const rfcVerificator = new RegExp(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    );
    const rfc = currentCompany.rfc.toString();
    if (rfc == "XAXX010101000" || rfc == "XEXX010101000") {
      if (currentCompany._type == "Moral") {
        setErrorRfc(false);
        setLabelRFC("");
      } else {
        setErrorRfc(true);
        setLabelRFC("El RFC genérico solo aplica para personas morales");
      }
    } else if (rfc.length > 0) {
      if (currentCompany._type == "Física") {
        if (rfc.length == 13) {
          if (!rfcVerificator.test(currentCompany.rfc)) {
            setErrorRfc(true);
            setLabelRFC("El formarto del RFC ingresado es incorrecto");
          } else {
            setErrorRfc(false);
            setLabelRFC("");
          }
        } else {
          setErrorRfc(true);
          setLabelRFC("El RFC para personas físicas debe ser de 13 caracteres");
        }
      } else {
        if (rfc.length == 12) {
          if (!rfcVerificator.test(currentCompany.rfc)) {
            setErrorRfc(true);
            setLabelRFC("El formarto del RFC ingresado es incorrecto");
          } else {
            setErrorRfc(false);
            setLabelRFC("");
          }
        } else {
          setErrorRfc(true);
          setLabelRFC("El RFC para personas morales debe ser de 12 caracteres");
        }
      }
    } else {
      setErrorRfc(false);
      updateKey("verifyData", false);
    }
  };

  const verifyDataForm = async (
    setError,
    labelError,
    typeError,
    formDataName,
    errorN
  ) => {
    if (typeError == "Email") {
      if (formDataName.length > 0) {
        if (!regexEmail.test(formDataName)) {
          await setError(true);
          await labelError("El correo no es válido");
        } else {
          await setError(false);
          await labelError("");
        }
      }
    }
  };

  const verifyAdditionalData = async () => {
    if (currentCompany.additional_data_prompt == "No") {
      document.getElementById("additional_data").disabled = true;
    } else {
      document.getElementById("additional_data").disabled = false;
    }
  };

  const getCurrentCompany = async (currentData) => {
    let data;
    let found = false;

    companies.map((company) => {
      if (company._id === currentCompanyId) {
        data = company;
        found = true;
      }
    });

    if (!found) {
      pendingCompanies.map((company) => {
        if (company._id === currentCompanyId) {
          data = company;
          found = true;
        }
      });
    }

    if (currentData) data = currentData;

    if (data.factureContacts) setCurrentContacts(data.factureContacts);

    await Methods.getCompanyDocs(data._id, setCompanyDocs);
    let prefactureOptionsAux = await Methods.getPrefactureOptions();

    if (prefactureOptionsAux.length) {
      setFactureAditionalOptions(prefactureOptionsAux);
      setFactureAux({
        currentFacturePaymentType: data.facturePaymentType
          ? setFacturesValues(data.facturePaymentType, prefactureOptionsAux)
          : setDefaulFactureOptionValue(
              "facturePaymentType",
              prefactureOptionsAux
            ),
        currentCFDI: data.factureCFDI
          ? setFacturesValues(data.factureCFDI, prefactureOptionsAux)
          : setDefaulFactureOptionValue(
              "factureCFDI",
              prefactureOptionsAux,
              data
            ),
        currentFacturePaymentMethod: data.facturePaymentMethod
          ? setFacturesValues(data.facturePaymentMethod, prefactureOptionsAux)
          : setDefaulFactureOptionValue(
              "facturePaymentMethod",
              prefactureOptionsAux
            ),
        currentFactureAgent: data.factureAgent
          ? setFacturesValues(data.factureAgent, prefactureOptionsAux)
          : setDefaulFactureOptionValue("factureAgent", prefactureOptionsAux),
      });
    }

    setCurrentCompany(data);
    setCurrentCompanyAux({ ...data });
    if (
      data.factureAgent != "" &&
      data.factureAgent != "_" &&
      data.factureAgent != undefined
    ) {
      let response = await Methods.getContpaqAgent(data.factureAgent);
      if (response != null) {
        setLabelAgent(response.name);
        setErrorAgent(false);
      } else {
        setLabelAgent("El numero de agente no existe en Contpaq");
        setErrorAgent(true);
      }
      setShowAgent(true);
    }
    setCurrentMunicipality(data.municipality);
    setCurrentState(data.state);
  };

  const getDataByZipCode = async (currentCP) => {
    let api = (await Methods.getApiData("sepomex"))[0];
    api.apiKey = JSON.parse(decrypt(api.apiKey));
    try {
      let response = await axios({
        url: `${api.apiLink}${currentCP}?token=${api.apiKey}`,
        method: "GET",
      });
      await setZipCodeData(response.data);
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
    }
  };

  const handleFactureAgent = async (e) => {
    if (e.target.value != "" && e.target.value != "_") {
      let response = await Methods.getContpaqAgent(e.target.value);
      if (response != null) {
        setLabelAgent(response.name);
        updateKey(e.target.id, e.target.value);
        setErrorAgent(false);
      } else {
        setLabelAgent("El numero de agente no existe en Contpaq");
        updateKey(e.target.id, "_");
        setErrorAgent(true);
      }
      setShowAgent(true);
    } else {
      updateKey(e.target.id, "_");
      setErrorAgent(false);
      setShowAgent(false);
    }
  };

  const setZipCodeData = async (data) => {
    let suburb = [];
    if (data.length > 0) {
      setCurrentMunicipality(data[0].response.municipio);
      setCurrentState(data[0].response.estado);
      await data.forEach((element) => {
        const data = element.response.asentamiento;
        suburb.push(data);
      });
    }
    setCurrentSuburbs(suburb);
  };

  const updateKey = (keyToBeUpdated, valueToBeUpdated) => {
    let _data = currentCompany;
    _data[keyToBeUpdated] = valueToBeUpdated;
    setCurrentCompany(_data);
  };

  const handleSaveData = async () => {
    setIsUpdatingCompany(true);

    if (verifyErrors()) {
      setIsUpdatingCompany(false);
      return;
    }

    let data = {
      attributes: [],
      _id: currentCompany._id,
    };

    currentCompany.municipality = currentMunicipality;
    currentCompany.state = currentState;
    for (let key in currentCompany) {
      let value = currentCompany[key];
      if (key != "factureContacts" && value != currentCompanyAux[key]) {
        if (key == "business_name") {
          value = value.toUpperCase();
        }

        if (
          (key == "state" || key == "_type" || key == "_number") &&
          value != "" &&
          key != "_id" &&
          key != "user_id"
        ) {
          if (key === "number" || key === "type") {
            data.attributes.push({
              updateKey: `_${key}`,
              updateValue: value,
              expressionAttributeName: `#_${key}`,
            });
          } else {
            data.attributes.push({
              updateKey: key,
              updateValue: value,
              expressionAttributeName: `#${key}`,
            });
          }
        } else if (
          value != "" &&
          key != "_id" &&
          key != "user_id" &&
          key != "_type"
        ) {
          data.attributes.push({ updateKey: key, updateValue: value });
        }
      }
    }

    filterContacts(data);

    if (data.attributes.length == 0) {
      return;
    }

    await verifyCompaniesinRFC(data.attributes);
    await Methods.updateCompany(data);
    await getCurrentCompany(currentCompany);
    setIsUpdatingCompany(false);
    currentCompany._id == context.user.company._id &&
      context.onLoad(context.user.data.email, context.token, true);
  };

  const verifyCompaniesinRFC = async (values) => {
    for (let value of values) {
      if (value.updateKey == "rfc") {
        let company = await Methods.getCompaniesByRFC({
          RFC: value.updateValue,
        });
        if (company.length) {
          values.push({
            updateKey: "groupCompaniesRFC",
            updateValue: company[0].groupCompaniesRFC || [],
          });
        }
        return;
      }
    }
  };

  const verifyErrors = () => {
    const errors = [
      { error: errorRfc, label: "errorRfc" },
      { error: errorPhone, label: "errorPhone" },
      { error: errorEmail, label: "errorEmail" },
      { error: errorPhoneSec, label: "errorPhoneSec" },
      { error: errorEmailSec, label: "errorEmailSec" },
      { error: errorPhoneThr, label: "errorPhoneThr" },
      { error: errorAgent, label: "errorAgent" },
      { error: errorEmailThr, label: "errorEmailThr" },
    ];

    let labeErrors = {
      errorRfc: "El formarto del RFC ingresado es incorrecto",
      errorPhone:
        "El número de teléfono ingresado en representante legal debe tener 10 dígitos",
      errorEmail: "El correo ingresado en representante legal no es válido",
      errorPhoneSec:
        "El número de teléfono ingresado en contacto principal de la empresa debe tener 10 dígitos",
      errorEmailSec:
        "El correo ingresado en contacto principal de la empresa no es válido",
      errorPhoneThr:
        "El número de teléfono ingresado en contacto para pago de factura debe tener 10 dígitos",
      errorAgent: "El numero de agente no existe en Contpaq",
      errorEmailThr:
        "El correo ingresado en contacto para pago de factura no es válido",
    };

    for (let errorAux of errors) {
      if (errorAux.error) {
        notify(labeErrors[errorAux.label], "error");
        return true;
      }
    }

    if (
      currentCompany.additional_data_prompt == "Sí" &&
      currentCompany.additional_data == ""
    ) {
      notify("No hay ningún dato adicional para la factura", "error");
      return true;
    }

    if (currentCompany.rfc == "" || currentCompany.rfc == undefined) {
      notify("La empresa requiere de un RFC para ser creada", "error");
      return true;
    }

    if (
      currentCompany.client_number == "" ||
      currentCompany.client_number == undefined
    ) {
      notify("La empresa requiere de un número de cliente", "error");
      return true;
    }

    for (let contact of currentContacts) {
      if (contact.email || contact.name) {
        if (!contact.email || !regexEmail.test(contact.email)) {
          notify(
            "Uno de los contactos para facturación cuenta con un email incorrecto, verifica tu información",
            "error"
          );
          return true;
        }

        if (!contact.name) {
          notify(
            "El nombre para contacto de facturación no puede estar vacío",
            "error"
          );
          return true;
        }
      }
    }

    return false;
  };

  const filterContacts = (data) => {
    let realContacts = [];
    for (let contact of currentContacts) {
      if (contact.email && contact.name) {
        realContacts.push(contact);
      }
    }

    data.attributes.push({
      updateKey: "factureContacts",
      updateValue: realContacts,
    });
  };

  const handleUploadCompanyDoc = async (file, name, key, lastDocId) => {
    await Methods.uploadCompanyDoc(
      currentCompany._id,
      file,
      name,
      key,
      lastDocId
    );
    setUpdateInfo(!updateInfo);
  };

  const canEditCompany = () => {
    if (currentCompany.isApproved) {
      return canEdit && isCaintraUser;
    }
    return canEdit;
  };

  const renderCompanyDocs = () => {
    const requiredDocs = [
      { key: "Comprobante de Domicilio", name: "Comprobante-De-Domicilio" },
      { key: "Poder Legal", name: "Poder-Legal" },
      { key: "Acta Constitutiva", name: "Acta-Constitutiva" },
      { key: "Estado de Cuenta", name: "Estado-de-Cuenta" },
      {
        key: "INE Contacto Recursos Humanos",
        name: "INE-Contacto-Recursos-Humanos",
      },
      { key: "Constancia RFC", name: "Constancia-RFC" },
      { key: "INE Representante Legal", name: "INE-Representante-Legal" },
    ];

    return (
      <Table striped bordered responsive hover className="company-docs h-50">
        <thead>
          <tr>
            <th>Documento</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {requiredDocs.map((requiredDoc, index) => {
            const includesDoc = (requiredCompanyDoc, uploadedCompanyDocs) => {
              let result = false;

              for (let doc of uploadedCompanyDocs) {
                doc.key == requiredCompanyDoc && (result = true);
              }
              return result;
            };

            if (includesDoc(requiredDoc.key, companyDocs)) {
              return companyDocs.map((doc, docIndex) => {
                if (requiredDoc.key === doc.key) {
                  return (
                    <tr key={docIndex}>
                      <td>
                        <h6>{docNames[index]}</h6>
                      </td>
                      <td>{doc.name}</td>
                      <td style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <a
                            href={doc.url}
                            key={index}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faCloudDownloadAlt} />
                          </a>

                          {canEditCompany() && (
                            <Form.File
                              hidden
                              onChange={(e) => {
                                e.preventDefault();
                                if (e.target.files[0]) {
                                  handleUploadCompanyDoc(
                                    e.target.files[0],
                                    requiredDoc.name,
                                    requiredDoc.key,
                                    doc._id
                                  );
                                }
                              }}
                              id={`${index}_file`}
                              label={
                                <FontAwesomeIcon
                                  className="form-file-label"
                                  icon={faCloudUploadAlt}
                                />
                              }
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                }
              });
            } else {
              return (
                <tr>
                  <td>
                    <h6>{docNames[index]}</h6>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                      color="orange"
                      icon={faExclamationTriangle}
                    />
                  </td>
                  <td style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        margin: "auto",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {canEditCompany() ? (
                        <Form.File
                          hidden
                          onChange={(e) => {
                            e.preventDefault();
                            if (e.target.files[0]) {
                              handleUploadCompanyDoc(
                                e.target.files[0],
                                requiredDoc.name,
                                requiredDoc.key
                              );
                            }
                          }}
                          id={`${index}_file`}
                          label={
                            <FontAwesomeIcon
                              className="form-file-label"
                              icon={faCloudUploadAlt}
                            />
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="orange"
                          icon={faBan}
                          style={{ height: "1.65rem" }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    );
  };

  const handleStartProcess = async () => {
    let _errors = 0;

    let notRequiredKeys = [
      "additional_data_prompt",
      "additional_data",
      "isCaintraCompany",
      "isApproved",
      "processFinished",
      "requireSife",
      "factureContacts",
      "groupCompaniesRFC",
    ];

    for (let key in currentCompany) {
      if (
        currentCompany[key] == "" &&
        key != "int_number" &&
        !notRequiredKeys.includes(key) &&
        key != "line_of_business" &&
        key != "logo_url"
      ) {
        _errors++;
      }
    }

    if (_errors == 0) {
      await Methods.startCompanyProcess(
        currentCompany._id,
        currentCompany.business_name
      );
      setUpdateList(!updateList);
      setTab("listCompanies");
    } else {
      notify(
        "Todavía no llenas todos los datos del registro, es imposible iniciar el proceso",
        "error"
      );
      return;
    }
  };

  return (
    <motion.div
      initial={{ x: 30, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.25 }}
      className="company-form"
    >
      <Row className="title-container">
        <BiArrowBack
          onClick={() => {
            setTab("listCompanies");
            setUpdateList(!updateList);
          }}
        />{" "}
        <h4>Verificación de Datos de Compañía</h4>
      </Row>

      <Row>
        <Col>
          <Form className="" autoComplete={"off"}>
            <Row>
              <Col>
                <p>Datos Generales</p>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="client_number">
                  <Form.Label>Número de cliente</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el número de cliente"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    onInput={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.client_number || ""}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="business_name">
                  <Form.Label>Nombre de la Empresa</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el nombre de la empresa"
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      updateKey(e.target.id, e.target.value);
                    }}
                    defaultValue={currentCompany.business_name}
                  />
                </Form.Group>
                <Form.Group controlId="social_reason">
                  <Form.Label>División comercial</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce la división comercial"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.social_reason}
                  />
                </Form.Group>

                <Form.Group controlId="corporative">
                  <Form.Label>Corporativo</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Corporativo"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.corporative}
                  />
                </Form.Group>

                <Form.Group controlId="line_of_business">
                  <Form.Label>Giro</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el giro"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.line_of_business}
                  />
                </Form.Group>

                <Form.Group controlId="street">
                  <Form.Label>Calle</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce la calle"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.street}
                  />
                </Form.Group>

                <Form.Group controlId="int_number">
                  <Form.Label>Número interior</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el número interior de la empresa"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.int_number}
                  />
                </Form.Group>

                <Form.Group controlId="municipality">
                  <Form.Label>Municipio/Delegación</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Municipio o delegación de la empresa"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      setCurrentMunicipality(e.target.value);
                    }}
                    value={currentMunicipality}
                  />
                </Form.Group>

                <Form.Group controlId="administrative_expense">
                  <Form.Label>Gasto administrativo</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      readOnly={!canEditCompany()}
                      type="number"
                      min="0"
                      max="100"
                      onInput={(e) => {
                        e.target.value = (0, e.target.value)
                          .toString()
                          .slice(0, 5);
                      }}
                      placeholder="Porcentaje del gasto administrativo de la empresa"
                      onChange={(e) => updateKey(e.target.id, e.target.value)}
                      defaultValue={currentCompany.administrative_expense}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="_type">
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      verifyRfc();
                    }}
                  >
                    <option disabled selected hidden>
                      {currentCompany._type || ""}
                    </option>
                    <option selected={currentCompany._type == "Física"}>
                      Física
                    </option>
                    <option selected={currentCompany._type == "Moral"}>
                      Moral
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="commercial_reason">
                  <Form.Label>Nombre Comercial</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Nombre comercial de la empresa"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.commercial_reason}
                  />
                </Form.Group>

                <Form.Group controlId="rfc">
                  <Form.Label>RFC</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce la clave única"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      verifyRfc();
                    }}
                    defaultValue={currentCompany.rfc}
                  />
                  {errorRfc && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelErrorRFC}{" "}
                    </Form.Label>
                  )}
                </Form.Group>

                <Form.Group controlId="payment_period">
                  <Form.Label>Periodo de dispersión del practicante</Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {currentCompany.payment_period || ""}
                    </option>
                    <option
                      selected={currentCompany.payment_period == "Catorcenal"}
                    >
                      Catorcenal
                    </option>
                    <option
                      selected={currentCompany.payment_period == "Quincenal"}
                    >
                      Quincenal
                    </option>
                    <option
                      selected={
                        currentCompany.payment_period == "Quincenal-Mensual"
                      }
                    >
                      Quincenal-Mensual
                    </option>
                    <option
                      selected={
                        currentCompany.payment_period == "Quincenal-Mensual C"
                      }
                    >
                      Quincenal-Mensual C
                    </option>
                    <option
                      selected={currentCompany.payment_period == "Quincenal 2"}
                    >
                      Quincenal 2
                    </option>
                    <option
                      selected={currentCompany.payment_period == "Mensual 15"}
                    >
                      Mensual 15
                    </option>
                    <option
                      selected={currentCompany.payment_period == "Mensual 30"}
                    >
                      Mensual 30
                    </option>
                    <option
                      selected={
                        currentCompany.payment_period == "Mensual 15 BAT"
                      }
                    >
                      Mensual 15 BAT
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="ext_number">
                  <Form.Label>Número exterior</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el número exterior de la empresa"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.ext_number}
                  />
                </Form.Group>

                <Form.Group controlId="zip_code">
                  <Form.Label>Código Postal</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    onInput={async (e) => {
                      e.target.value = (0, e.target.value)
                        .toString()
                        .slice(0, 5);
                      if (e.target.value.length == 5) {
                        await getDataByZipCode(e.target.value);
                      }
                    }}
                    placeholder="Código postal de la empresa"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.zip_code}
                  />
                </Form.Group>

                <Form.Group controlId="suburb">
                  <Form.Label>Colonia</Form.Label>
                  <Form.Control
                    className="input"
                    readOnly={true}
                    as="select"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      {currentCompany.suburb}
                    </option>
                    {currentSuburbs.map((element) => {
                      return <option>{element}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="state">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Estado donde se ubica la empresa"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      setCurrentState(e.target.value);
                    }}
                    value={currentState}
                    autocomplete="no"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Características del Servicio</p>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="interns_medical_insurance">
                  <Form.Label>
                    ¿Desea contratar una póliza de seguro para los practicantes?
                  </Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                  >
                    <option disabled selected hidden>
                      {currentCompany.interns_medical_insurance || ""}
                    </option>
                    <option
                      selected={
                        currentCompany.interns_medical_insurance == "Sí"
                      }
                    >
                      Sí
                    </option>
                    <option
                      selected={
                        currentCompany.interns_medical_insurance == "No"
                      }
                    >
                      No
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="internship_bank">
                  <Form.Label>Banco para depósito de beca</Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    readOnly
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {currentCompany.internship_bank || ""}
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Ninguno"}
                    >
                      Ninguno
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Banorte"}
                    >
                      Banorte
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Bancomer"}
                    >
                      Bancomer
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Santander"}
                    >
                      Santander
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Banamex"}
                    >
                      Banamex
                    </option>
                    <option selected={currentCompany.internship_bank == "HSBC"}>
                      HSBC
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Afirme"}
                    >
                      Afirme
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Banjio"}
                    >
                      Banjio
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Banjercito"}
                    >
                      Banjercito
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Bancoppel"}
                    >
                      Bancoppel
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Inbursa"}
                    >
                      Inbursa
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Scotiabank"}
                    >
                      Scotiabank
                    </option>
                    <option
                      selected={
                        currentCompany.internship_bank == "Banco Azteca"
                      }
                    >
                      Banco Azteca
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Banregio"}
                    >
                      Banregio
                    </option>
                    <option
                      selected={currentCompany.internship_bank == "Bancomext"}
                    >
                      Bancomext
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="insurance_payment">
                  <Form.Label>Cobro de Seguro</Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    placeholder="¿Empresa o practicante?"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.insurance_payment}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {currentCompany.insurance_payment || ""}
                    </option>
                    <option
                      selected={currentCompany.insurance_payment == "Empresa"}
                    >
                      Empresa
                    </option>
                    <option
                      selected={
                        currentCompany.insurance_payment == "Practicante"
                      }
                    >
                      Practicante
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="additional_data_prompt">
                  <Form.Label>
                    ¿La factura requiere algún dato adicional?
                  </Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      verifyAdditionalData();
                    }}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {currentCompany.additional_data_prompt || ""}
                    </option>
                    <option
                      selected={currentCompany.additional_data_prompt == "Sí"}
                    >
                      Sí
                    </option>
                    <option
                      selected={currentCompany.additional_data_prompt == "No"}
                    >
                      No
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="additional_data">
                  <Form.Label>¿Cuál?</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.additional_data}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="requireSife">
                  <Form.Label>
                    ¿Desea contratar la firma electrónica para los practicantes?
                  </Form.Label>

                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                    }}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {currentCompany.requireSife || "No"}
                    </option>
                    <option selected={currentCompany.requireSife == "Sí"}>
                      Sí
                    </option>
                    <option selected={currentCompany.requireSife == "No"}>
                      No
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="requirePurchaseOrder">
                  <Form.Label>
                    ¿Necesita ingresar la OC antes de enviar su factura a
                    timbrar?
                  </Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {currentCompany.requirePurchaseOrder || "No"}
                    </option>
                    <option
                      selected={currentCompany.requirePurchaseOrder == "Sí"}
                    >
                      Sí
                    </option>
                    <option
                      selected={currentCompany.requirePurchaseOrder == "No"}
                    >
                      No
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="facturePaymentType">
                  <Form.Label>Forma de pago</Form.Label>

                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                    }}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {`${
                        currentCompany.facturePaymentType
                          ? `${
                              currentFacturePaymentType?.satKey -
                              currentFacturePaymentType?.concept
                            }`
                          : "."
                      }`}
                    </option>
                    {factureAditionalOptions.length &&
                      factureAditionalOptions.map((element, index) => {
                        if (element.type == "facturePaymentType") {
                          return (
                            <option
                              id={index}
                              value={element._id}
                              selected={
                                currentCompany.facturePaymentType == element._id
                              }
                            >{`${element.satKey} - ${element.concept}`}</option>
                          );
                        }
                      })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="factureCFDI">
                  <Form.Label>Uso de CFDI</Form.Label>
                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {` ${
                        currentCompany.factureCFDI
                          ? `${currentCFDI?.satKey} '-' ${currentCFDI?.concept}`
                          : "."
                      }`}
                    </option>
                    {factureAditionalOptions.length &&
                      factureAditionalOptions.map((element, index) => {
                        if (element.type == "factureCFDI") {
                          return (
                            <option
                              id={index}
                              value={element._id}
                              selected={
                                currentCompany.factureCFDI == element._id
                              }
                            >{`${element.satKey} - ${element.concept}`}</option>
                          );
                        }
                      })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="facturePaymentMethod">
                  <Form.Label>Método de pago</Form.Label>

                  <Form.Control
                    className="input"
                    disabled={!canEditCompany()}
                    as="select"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                    }}
                  >
                    <option disabled selected hidden>
                      {" "}
                      {` ${
                        currentCompany.facturePaymentMethod
                          ? ` ${currentFacturePaymentMethod?.satKey} '-' ${currentFacturePaymentMethod?.concept}`
                          : "."
                      }`}
                    </option>
                    {factureAditionalOptions.length &&
                      factureAditionalOptions.map((element, index) => {
                        if (element.type == "facturePaymentMethod") {
                          return (
                            <option
                              id={index}
                              value={element._id}
                              selected={
                                currentCompany.facturePaymentMethod ==
                                element._id
                              }
                            >{`${element.satKey} - ${element.concept}`}</option>
                          );
                        }
                      })}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6} xs={12} sm={12}>
                <Form.Group controlId="factureAgent">
                  <Form.Label>No de agente ejecutivo</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={!canEditCompany()}
                    onBlur={(e) => handleFactureAgent(e)}
                    defaultValue={currentCompany.factureAgent}
                  />
                  {showAgent && (
                    <Form.Label className="agent"> {labelAgent} </Form.Label>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Representante Legal</p>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="legal_rep_fullname">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el nombre del representante legal"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.legal_rep_fullname}
                  />
                </Form.Group>

                <Form.Group controlId="legal_rep_phone">
                  <Form.Label>Teléfono (10 dígitos)</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el teléfono"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                    }}
                    defaultValue={currentCompany.legal_rep_phone}
                  />
                  {errorPhone && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelPhone}{" "}
                    </Form.Label>
                  )}
                </Form.Group>

                <Form.Group controlId="legal_rep_email">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="email"
                    placeholder="El correo electrónico del representante legal"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      verifyDataForm(
                        setErrorEmail,
                        setLabelEmail,
                        "Email",
                        currentCompany.legal_rep_email,
                        errorEmail
                      );
                    }}
                    defaultValue={currentCompany.legal_rep_email}
                  />
                  {errorEmail && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelEmail}{" "}
                    </Form.Label>
                  )}
                </Form.Group>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="legal_rep_position">
                  <Form.Label>Puesto</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="¿Cuál es el puesto del representante legal?"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.legal_rep_position}
                  />
                </Form.Group>
                <Form.Group controlId="legal_rep_department">
                  <Form.Label>{context.getDepartmentField(true)}</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder={`¿A qué ${context.getDepartmentField()} pertenece?`}
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.legal_rep_department}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  Contacto Principal de la Empresa con Caintra (Manejará el
                  Sistema y se encargará de los Practicantes)
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="org_admin_fullname">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Nombre del administrador"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.org_admin_fullname}
                  />
                </Form.Group>

                <Form.Group controlId="org_admin_phone">
                  <Form.Label>Teléfono (10 dígitos)</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el teléfono del administrador"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                    }}
                    defaultValue={currentCompany.org_admin_phone}
                  />
                  {errorPhoneSec && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelPhoneSec}{" "}
                    </Form.Label>
                  )}
                </Form.Group>

                <Form.Group controlId="org_admin_email">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="email"
                    placeholder="Introduce el correo del administrador"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      verifyDataForm(
                        setErrorEmailSec,
                        setLabelEmailSec,
                        "Email",
                        currentCompany.org_admin_email,
                        errorEmailSec
                      );
                    }}
                    defaultValue={currentCompany.org_admin_email}
                  />
                  {errorEmailSec && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelEmailSec}
                    </Form.Label>
                  )}
                </Form.Group>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="org_admin_position">
                  <Form.Label>Puesto</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="¿Cuál es el puesto del administrador?"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.org_admin_position}
                  />
                </Form.Group>
                <Form.Group controlId="org_admin_department">
                  <Form.Label>{context.getDepartmentField(true)}</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder={`¿A qué ${context.getDepartmentField()} pertenece?`}
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.org_admin_department}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Contacto para pago de factura</p>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="billing_contact_fullname">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el nombre del contacto"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.billing_contact_fullname}
                  />
                </Form.Group>

                <Form.Group controlId="billing_contact_phone">
                  <Form.Label>Teléfono (10 dígitos)</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el teléfono"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                    }}
                    defaultValue={currentCompany.billing_contact_phone}
                  />
                  {errorPhoneThr && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelPhoneThr}{" "}
                    </Form.Label>
                  )}
                </Form.Group>

                <Form.Group controlId="billing_contact_email">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="email"
                    placeholder="Introduce el correo del contacto"
                    onChange={(e) => {
                      updateKey(e.target.id, e.target.value);
                      verifyDataForm(
                        setErrorEmailThr,
                        setLabelEmailThr,
                        "Email",
                        currentCompany.billing_contact_email,
                        errorEmailThr
                      );
                    }}
                    defaultValue={currentCompany.billing_contact_email}
                  />
                  {errorEmailThr && (
                    <Form.Label className="error-label">
                      {" "}
                      {labelEmailThr}{" "}
                    </Form.Label>
                  )}
                </Form.Group>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <Form.Group controlId="billing_contact_position">
                  <Form.Label>Puesto</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder="Introduce el puesto del contacto"
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.billing_contact_position}
                  />
                </Form.Group>
                <Form.Group controlId="billing_contact_department">
                  <Form.Label>{context.getDepartmentField(true)}</Form.Label>
                  <Form.Control
                    readOnly={!canEditCompany()}
                    type="text"
                    placeholder={`¿A qué ${context.getDepartmentField()} pertenece?`}
                    onChange={(e) => updateKey(e.target.id, e.target.value)}
                    defaultValue={currentCompany.billing_contact_department}
                  />
                </Form.Group>
              </Col>
            </Row>

            <FactureContacts
              currentContacts={currentContacts}
              setCurrentContacts={setCurrentContacts}
              canEditCompany={canEditCompany()}
              companyId={currentCompany._id}
            />

            <Row style={{ marginTop: "40px" }}>
              <Col>
                <p>Documentos cargados</p>
              </Col>
            </Row>

            <Row>
              <Col>{renderCompanyDocs()}</Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <div className="btn-container">
        {canEditCompany() && !currentCompany.processFinished && (
          <Button
            className="float-right mt-4"
            variant="primary"
            type="button"
            onClick={() => handleStartProcess()}
          >
            Iniciar proceso
          </Button>
        )}
        {canEditCompany() && (
          <Button
            className="float-right mt-4 mr-3"
            variant="primary"
            type="button"
            onClick={() => handleSaveData()}
          >
            {isUpdatingCompany ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Guardar Cambios"
            )}
          </Button>
        )}
      </div>
    </motion.div>
  );
}
